<template>
  <div class="main_right">
    <div class="goldCoins">
      <div class="goldTop">
        <div class="imgs">
          <div class="img one">
            <img src="../../../assets/student/dier.png" alt="">
            <div class="head_portrait">
              <img src="../../../assets/student/headPortrait.png" alt="">
            </div>
            <p class="name">{{ tableData[1] ? tableData[1].xsmc : '虚席以待' }}</p>
            <p class="fraction">{{ tableData[1] ? (tableData[1].zs + '个') : '' }}</p>
          </div>
          <div class="img two">
            <img src="../../../assets/student/diyi.png" alt="">
            <div class="head_portrait">
              <img src="../../../assets/student/headPortrait.png" alt="">
            </div>
            <p class="name">{{ tableData[0] ? tableData[0].xsmc : '虚席以待' }}</p>
            <p class="fraction">{{ tableData[0] ? (tableData[0].zs + '个') : '' }}</p>
          </div>
          <div class="img three">
            <img src="../../../assets/student/disan.png" alt="">
            <div class="head_portrait">
              <img src="../../../assets/student/headPortrait.png" alt="">
            </div>
            <p class="name">{{ tableData[2] ? tableData[2].xsmc : '虚席以待' }}</p>
            <p class="fraction">{{ tableData[2] ? (tableData[2].zs + '个') : '' }}</p>
          </div>
        </div>
      </div>
      <div class="goldBottom">
        <el-table v-if="tableData.length > 3" :row-class-name="tableRowClassName" class="table" style="height:100%;" :data="tableData" border :header-cell-style="{background:'#F1F8FF',color:'#333','text-align':'center'}">
            <el-table-column type="index" label="排名" width="100" align="center" />
            <el-table-column prop="dcmc" align="center" label="头像">
              <!-- <template slot-scope="scope"> -->
              <template v-slot>
                <span class="head">
                  <img src="../../../assets/student/headPortrait.png" alt="">
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="xsmc" label="姓名" align="center" />
            <el-table-column prop="zs" label="金币个数" align="center" />
        </el-table>
        <div v-else class="table" style="border:1px solid #EBEEF5;height:100%;position:relative;">
          <div class="default">
            <img src="../../../assets/student/defult.png" alt="">
            <span>虚席以待</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { studentCoinRank } from '@/api/student'
export default {
  data(){
    return{
      tableData:[]
    }
  },
  created() {
    // if (JSON.parse(sessionStorage.getItem('jxInfo')).bjid) {
      studentCoinRank({}).then(res => {
        if (res.success) {
          if (res.result.length) {
            this.tableData = res.result // .concat(res.result).concat(res.result).concat(res.result).concat(res.result)
          } else {
            this.$message.warning('暂无排行')
          }
        } else {
          this.$message.warning(res.resultDesc)
        }
      })
    // } else {
    //   this.$message.warning('暂无所属班级')
    // }
  },
  methods: {
    tableRowClassName(row) {
      return row.rowIndex < 3 ? 'hidden-row' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.main_right{
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
}
.goldCoins{
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
  border-radius: 10px;
  padding: 2%;
  box-sizing: border-box;
  .goldTop{
    height: 40%;
    // background: #eee;
    position: relative;
    .imgs{
      width: 40%;
      height: 95%;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%);
      // position: relative;
      .img{
        float: left;
        width: 33%;
        height: 100%;
        position: absolute;
        bottom: 0;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .img:nth-child(1){
        left: 0;
        .head_portrait{
          width: 2.6vw;
          height: 2.6vw;
          border: 1px solid #5990FF;
          border-radius: 50%;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translateX(-50%);
          img{
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name{
          position: absolute;
          top: 65%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1vw;
        }
        .fraction{
          position: absolute;
          top: 78%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1vw;
        }
      }
      .img:nth-child(2){
        width: 34%;
        height: 115%;
        left: 33%;
        .head_portrait{
          width: 3vw;
          height: 3vw;
          border: 1px solid #F5CF04;
          border-radius: 50%;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translateX(-50%);
          img{
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name{
          position: absolute;
          top: 65%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1vw;
        }
        .fraction{
          position: absolute;
          top: 78%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1vw;
        }
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .img:nth-child(3){
        left: 67%;
        height: 90%;
        .head_portrait{
          width: 2.4vw;
          height: 2.4vw;
          border: 1px solid #FE721D;
          border-radius: 50%;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translateX(-50%);
          img{
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name{
          position: absolute;
          top: 65%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1vw;
        }
        .fraction{
          position: absolute;
          top: 78%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1vw;
        }
      }
    }
  }
  .goldBottom{
    height: 60%;
  }
}
.head{
  display: block;
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 50%;
  border: 1px solid #5990FF;
  margin: auto;
  img{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.default{
  width: 18.33vw;
  height: 13vw;
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
  span{
    display: block;
    font-size: 1.3vw;
    color: #333;
    position: absolute;
    bottom: -3vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
<style lang="scss">
.main_right{
  .el-table{
    .hidden-row{
      display: none;
    }
  }
}
</style>